import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { subscriptionQueries } from '../../../../graphql/queries';
import PlanChooser from './Components/PlanChooser.js';
import FreeArticlesGrid from './Components/FreeArticlesGrid.js';
import FreeArticlesBanner from './Components/FreeArticlesBanner.js';
import SubscriptionArticleHeader from './Components/SubscriptionArticleHeader.js';
import { UserContext } from '../../../../Context';
import { isActiveSubscription } from '../../../../helpers/subscription.js';
import SubscriptionSkeleton from './Components/SubscriptionSkeleton.js';

const useStyles = makeStyles((theme) => ({
  productDetails: {
    marginBottom: '5rem',
    marginTop: '-1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem'
    }
  },
  title: {
    marginTop: '2rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 0
    }
  },
  description: {
    marginBottom: '2rem',
    display: 'block'
  },
  setsAndHands: {
    [theme.breakpoints.down('md')]: {
      padding: '4rem 2rem'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: '-1.5rem',
      width: 'calc(100% + 3rem)'
    }
  }
}));

export default function Subscription () {
  const { subscriptionId } = useParams();
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { t } = useTranslation();

  // get subscription
  const { data } = useQuery(subscriptionQueries.SubscriptionById(subscriptionId));

  if (!data) {
    return <SubscriptionSkeleton />;
  }

  const subscription = data.subscriptionById;

  // is the user already subscribed?
  let isSubscribed = false;
  if (user && user.activeSubscriptions) {
    isSubscribed = isActiveSubscription(user, subscription.id);
  }

  return (
    <>
      <SubscriptionArticleHeader
        subscription={subscription}
        isSubscribed={isSubscribed}
      />

      <Grid
        container
        direction={{ sm: 'column', lg: 'row' }}
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={{ xs: 1, sm: 3 }}
      >
        <Grid item xs={12} sm={8}>
          <Typography variant='h1' className={classes.title}>
            {t('subscription-details')}
          </Typography>
          <Typography variant='body4' className={classes.description}>
            {subscription.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <PlanChooser
            subscription={subscription}
            isSubscribed={isSubscribed}
          />
        </Grid>
      </Grid>
      <FreeArticlesBanner subscription={subscription} user={user} />
      <Typography variant='h3' sx={{ marginBottom: 4 }}>{t('try-these-free-articles')}</Typography>
      <FreeArticlesGrid subscription={subscription} />
    </>
  );
}
